<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div v-if="!event.IsRegistrationOpened" class="text-center">
          <h3>Регистрация на {{ event.Title }} закрыта</h3>
        </div>
        <b-form v-if="event.IsRegistrationOpened && !isPageLoading">
  <!--        <b-form-group
            label="Наличие ПДА 'Луч'"
            label-for="pda"
            :label-cols="3"
            :inline="true"
          >
            <b-input-group-prepend>
              <b-form-radio-group id="pda" name="pda">
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="pdaOwn"
                    name="pdaOwn"
                    class="custom-control-input"
                    :value="PdaVariants.Own"
                    v-model="request.pda"
                    :disabled="!IsPdaRentEnabled"
                  />
                  <label class="custom-control-label" for="pdaOwn">Свой</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="pdaRent"
                    name="pdaRent"
                    class="custom-control-input"
                    :value="PdaVariants.Rent"
                    v-model="request.pda"
                    :disabled="!IsPdaRentEnabled"
                  />
                  <label class="custom-control-label" for="pdaRent"
                    >Прокат</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="pdaNone"
                    name="pdaNone"
                    class="custom-control-input"
                    :value="PdaVariants.None"
                    v-model="request.pda"
                    :disabled="!IsPdaRentEnabled"
                  />
                  <label class="custom-control-label" for="pdaNone"
                    >Нет необходимости</label
                  >
                </div>
              </b-form-radio-group>
            </b-input-group-prepend>
            <small class="form-text text-muted" v-if="!IsPdaRentEnabled"
              >К сожалению, лимит орендных ПДА исчерпан.</small
            >
            <small class="form-text text-muted" v-if="IsPdaRentEnabled"
              >На игре будет возможность взять на прокат сей девайс за 10$, НО в
              этом случае ДО-ВНОСИТСЯ залог, являющийся полной "прокатной"
              стоимостью ПДА "ЛУЧ".* *То есть, если вы желаете взять на прокат
              ПДА "ЛУЧ" - вам необходимо будет отдать человеку, ответственному
              за прокат 30$ (прокатная стоимость) из которых 20$ вернутся к вам
              после окончания игры.
              <a target="_blank" href="https://youtu.be/yQOWRFQ9Y1M"
                >Подробнее</a
              ></small
            >
          </b-form-group>-->
          <b-form-group
            label="Наличие игрового паспорта"
            label-for="passport"
            :label-cols="3"
            :inline="true"
          >
            <b-input-group-prepend>
              <b-form-radio-group id="passport" name="passport">
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="hasPassport"
                    name="hasPassport"
                    class="custom-control-input"
                    :value="true"
                    v-model="request.has_passport"
                  />
                  <label class="custom-control-label" for="hasPassport"
                    >В наличии</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="lostPassport"
                    name="lostPassport"
                    class="custom-control-input"
                    :value="false"
                    v-model="request.has_passport"
                  />
                  <label class="custom-control-label" for="lostPassport"
                    >Утерян</label
                  >
                </div>
              </b-form-radio-group>
            </b-input-group-prepend>
          </b-form-group>
          <b-button
            variant="success"
            class="text-center"
            block
            @click="sendRequest()"
          >
            Подать заявку
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../api/stalk.net";

export default {
  name: "MemberRequest",
  components: {},
  props: {
    event: Object,
    member: Object
  },
  data() {
    return {
      isPageLoading: false,
      IsPdaRentEnabled: false,
      request: {
        // pda: API.consts.Pda.Own,
        shot_light: API.consts.ShotLight.Own,
        has_passport: true
      },
      // PdaVariants: API.consts.Pda
    };
  },
  beforeMount: function() {},
  computed: {},
  filters: {},

  methods: {
    sendRequest(event) {
      let self = this;

      self.isPageLoading = true;
      // if (!self.IsPdaRentEnabled) {
      //   self.request.pda = API.consts.Pda.Own;
      // }
      API.private
        .sendMembersEventRequest(self.event.Id, self.member.Id, self.request)
        .then(response => {
          self.isPageLoading = false;
          self.request.Id = response.data.id;
          self.$forceUpdate();
          self.emitToParent(event);
        })
        .catch(e => {
          console.error(e);
          self.showError({ message: e });
          self.isPageLoading = false;
        });
    },
    emitToParent() {
      this.$emit("requestSent", this.request.Id);
    }
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>

<style scoped></style>
