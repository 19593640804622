<template>
  <div class="animated fadeIn">
    <Spinner v-if="isPageLoading" />

    <div v-if="!isPageLoading && !event" class="card w-25 text-left">
      <div class="card-body">
        <h5 class="card-title">Пока нет доступных мероприятий</h5>
        <div class="card-body"></div>
      </div>
    </div>
    <div
      v-if="!isPageLoading && event"
      class="card text-left"
      style="max-width: 530px;"
    >
      <div class="card-body">
        <h5 class="card-title">{{ event.Title }}</h5>
        <table class="table table-hover table-responsive table-borderless">
          <tbody>
            <tr>
              <th scope="row" class="text-right">Даты проведения</th>
              <td>
                {{ event.StartsAt | formatDate }} -
                {{ event.EndsAt | formatDate }}
              </td>
            </tr>
            <tr v-if="!memberEvent || memberEvent.DiscountAmountUAH < 1">
              <th scope="row" class="text-right">
                Взнос до {{ event.DiscountUntil | formatDate }}
              </th>
              <td>{{ event.DiscountPriceUAH | formatCurrency }}</td>
            </tr>
            <tr
              v-if="
                memberEvent &&
                  memberEvent.DiscountAmountUAH > 0 &&
                  memberEvent.Confirmation !== requestConsts.Paid
              "
            >
              <th scope="row" class="text-right">
                Ваш взнос до {{ event.DiscountUntil | formatDate }}
              </th>
              <td>{{ memberEvent.DiscountAmountUAH | formatCurrency }}</td>
            </tr>
            <tr v-if="!memberEvent || memberEvent.AmountUAH < 1">
              <th scope="row" class="text-right">
                Взнос после {{ event.DiscountUntil | formatDate }}
              </th>
              <td>{{ event.PriceUAH | formatCurrency }}</td>
            </tr>
            <tr
              v-if="
                memberEvent &&
                  memberEvent.AmountUAH > 0 &&
                  memberEvent.Confirmation !== requestConsts.Paid
              "
            >
              <th scope="row" class="text-right">
                Ваш взнос после {{ event.DiscountUntil | formatDate }}
              </th>
              <td>{{ memberEvent.AmountUAH | formatCurrency }}</td>
            </tr>
            <tr v-if="!memberEvent || memberEvent.AfterRegAmountUAH < 1">
              <th scope="row" class="text-right">
                Взнос после {{ event.RegistrationUntil | formatDate }}
              </th>
              <td>
                {{ event.AfterRegClosedPriceUAH | formatCurrency }}
                <small>(закрытие регистрации)</small>
              </td>
            </tr>
            <tr
              v-if="
                memberEvent &&
                  memberEvent.AfterRegAmountUAH > 0 &&
                  memberEvent.Confirmation !== requestConsts.Paid
              "
            >
              <th scope="row" class="text-right">
                Ваш взнос после {{ event.RegistrationUntil | formatDate }}
              </th>
              <td>
                {{ memberEvent.AfterRegAmountUAH | formatCurrency }}
                <small>(закрытие регистрации)</small>
              </td>
            </tr>
            <tr v-if="event.Desc.length > 1">
              <th scope="row" class="text-right">История</th>
              <td>
                <span
                  class="btn-link"
                  style="cursor: pointer"
                  @click="showEventInfo()"
                  role="button"
                >
                  {{
                    event.Desc.length > 25
                      ? event.Desc.substring(0, 25) + "..."
                      : event.Desc
                  }}
                </span>
              </td>
            </tr>
            <tr v-if="memberEvent">
              <th scope="row" class="text-right">Участники</th>
              <td>
                <span
                  class="btn-link"
                  style="cursor: pointer"
                  @click="showEventMembers()"
                  role="button"
                  v-if="event.MembersInfo.Total > 0"
                >
                  Показать {{ event.MembersInfo.Total }} участников
                </span>
                <span v-if="event.MembersInfo.Total === 0">{{
                  event.MembersInfo.Total
                }}</span>
              </td>
            </tr>
            <tr v-if="event.MembersInfo">
              <th scope="row" class="text-right">Заявки</th>
              <td>
                <span
                  >({{ event.MembersInfo.Requests }}/{{
                    event.MembersInfo.Max
                  }})</span
                >
              </td>
            </tr>
            <tr v-if="memberEvent">
              <th scope="row" class="text-right">Статус заявки:</th>
              <td>
                <span v-if="memberEvent.Confirmation !== requestConsts.Paid">{{
                  getConfirmTitle(memberEvent.Confirmation)
                }}</span>
                <b v-if="memberEvent.Confirmation === requestConsts.Paid">{{
                  getConfirmTitle(memberEvent.Confirmation)
                }}</b>
                <span
                  v-if="
                    memberEvent.Confirmation === requestConsts.Paid &&
                      memberEvent.PayedAmountUAH > 0
                  "
                >
                  (<i>{{ memberEvent.PayedAmountUAH | formatCurrency }}</i
                  >)
                </span>
              </td>
            </tr>
            <tr
              v-if="
                memberEvent &&
                  memberEvent.Confirmation === requestConsts.Commented &&
                  memberEvent.Comment.length > 0
              "
            >
              <th scope="row" class="text-right">Комментарий организатора</th>
              <td>{{ memberEvent.Comment }}</td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="!memberEvent && event.IsRegistrationOpened"
          class="text-center"
        >
          <b-button
            v-if="memberFraction && memberFraction.places_left > 0"
            variant="primary"
            class="active mt-3"
            @click="showRequestModal()"
          >
            Подать заявку
          </b-button>
          <span v-if="memberFraction && memberFraction.places_left === 0"
            >К сожалению, Ваша группировка уже заполнена</span
          >
        </div>
      </div>
    </div>
    <b-modal
      v-if="!isPageLoading && event"
      ref="historyModal"
      class="modal-info"
      size="md"
      scrollable
      ok-only
      :title="'История мероприятия ' + event.Title"
    >
      <div class="d-block text-center">
        <p>{{ event.Desc }}</p>
      </div>
    </b-modal>
    <b-modal
      v-if="!isPageLoading && event"
      ref="requestModal"
      class="modal-info"
      size="md"
      scrollable
      hide-footer
      :title="'Заявка на мероприятие ' + event.Title"
    >
      <div class="d-block text-center">
        <MemberRequest
          v-if="event && member"
          :event="event"
          :member="member"
          v-on:requestSent="requestSent"
        ></MemberRequest>
      </div>
    </b-modal>
  </div>
</template>

<script>
import API from "@/api/stalk.net";
import MemberRequest from "@/components/MemberRequest";
import Spinner from "../../components/common/Spinner";

export default {
  name: "Events",
  components: {
    Spinner,
    MemberRequest
  },
  data() {
    return {
      isPageLoading: false,
      event: null,
      member: {},
      memberEvent: null,
      baseURL: API.baseURL,
      requestConsts: API.consts.Request,
      currentMember: {},
      fractions: [],
      memberFraction: {}
    };
  },
  methods: {
    loadData() {
      let self = this;

      self.isPageLoading = true;
      self.$store.state.dataLoading
        .then(() => {
          self.member = self.$store.state.member.filter(character => character.IsActive)[0]
          return API.public.loadFractions(true);
        })
        .then(resp => {
          self.fractions = resp.data;
          self.fractions.forEach(fr => {
            if (fr.id === self.member.FractionId) {
              self.memberFraction = fr;
            }
          });
          return API.public.findActiveRegEvent();
        })
        .then(resp => {
          if (resp.data) {
            self.event = resp.data;

            //format prices
            self.event.DiscountPriceUAH = parseFloat(
              resp.data.DiscountPriceUAH
            );
            self.event.PriceUAH = parseFloat(resp.data.PriceUAH);

            return API.private.getMembersEventRequest(
              self.event.Id,
              self.member.Id
            );
          }
          return Promise.reject();
        })
        .then(resp => {
          if (resp.data) {
            self.memberEvent = resp.data;

            self.memberEvent.PayedAmountUAH = parseFloat(
              resp.data.PayedAmountUAH
            );
          }
          self.isPageLoading = false;
        })
        .catch(self.catchError);
    },
    catchError(e) {
      let self = this;

      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              API.private.logout();
              self.$router.replace("/login");
            }
            break;
        }

        if (e.response.data.error !== "ERR_NOT_FOUND") {
          console.error(e.response.data);
          self.showError({ message: e.response });
        }
      }
      self.isPageLoading = false;
    },
    showEventInfo() {
      this.$refs.historyModal.show();
    },
    showRequestModal() {
      this.$refs.requestModal.show();
    },
    showEventMembers() {
      this.$router.push({
        path: "event/" + this.event.Id + "/members",
        params: { id: this.event.Id }
      });
    },
    requestSent() {
      this.$refs.requestModal.hide();
      this.loadData();
    },
    getConfirmTitle(data) {
      return API.getConfirmTitle(data);
    }
  },
  beforeMount: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка ",
      type: "error"
    }
  }
};
</script>
